<template>
  <div class="main-bg fill-height">
    <main-app-toolbar />
    <v-main>
      <v-container fluid class="pa-0 ma-0">
        <router-view />
      </v-container>
      <global-snackbar />
    </v-main>
  </div>
</template>

<script>
const MainAppToolbar = () =>
  import("@/components/miscellaneous/toolbars/MainAppToolbar.vue");
export default {
  components: {
    MainAppToolbar
  }
};
</script>
